, report: true<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div v-if="ready">
          <crud-list
            v-model="unidadesNegocio"
            :headers="headers"
            :actions="actions"
            :slots="['item.telefones']"
            :permission="'list_unidade_negocio'"
            :sort-by="['razao_social']"
            :sort-desc="[false]"
            :export-pdf-xlsx="true"
          >
            <template
              v-if="hasPermission('inserir_unidade_negocio')"
              v-slot:btnCadastro
            >
              <v-col>
                <planilha-upload-dialog
                  :template-id="4"
                  block
                  color="primary"
                />
              </v-col>
              <v-col>
                <v-btn
                  class="text-none text-white"
                  block
                  rounded
                  color="primary"
                  title="Cadastrar nova"
                  @click="$router.push({ path: 'unidadesnegocio/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import UnidadesNegocioStore, { BOOTSTRAP, DELETE_UNIDADE } from '@/store/modules/unidadesNegocio'
  import PlanilhaUploadDialog from '../../components/PlanillhaUpload'

  export default {
    components: {
      PlanilhaUploadDialog,
    },
    data () {
      return {
        actions: [
          {
            title: 'Editar Unidade de negócio',
            color: 'info darken-3',
            click: item => this.editarUnidadeNegocio(item),
            icon: 'mdi-pencil',
            permission: 'editar_unidade_negocio',
          },
          {
            title: 'Excluir Unidade de negócio',
            color: 'red darken-3',
            click: item => this.DELETE_UNIDADE(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_unidade_negocio',
          },
        ].filter(action => this.$store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', sortable: false, groupable: false, floatingfilter: false, value: 'actions', width: '20px' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Código de Centro', value: 'cod_centro', width: 'auto', report: true, styleReport: { halign: 'right', cellWidth: 35 } },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'CNPJ', value: 'cnpj', width: 'auto', fixed: true, report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Razão Social', value: 'razao_social', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Filial', value: 'filial', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Gestor', value: 'gestor.nome', width: 'auto', fixed: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Email Supervisor de Captação', value: 'email_supervisor', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Email Administrador do Clube', value: 'email_adm_clube', width: 'auto' },
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Telefones', value: 'telefones', width: 'auto' },
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Logradouro', value: 'endereco.logradouro', width: 'auto' },
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Número', value: 'endereco.numero', width: 'auto' },
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Complemento', value: 'endereco.complemento', width: 'auto' },
          { align: 'left', sortable: false, groupable: false, floatingfilter: false, text: 'Bairro', value: 'endereco.bairro', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Cidade', value: 'endereco.cidade.nome', width: 'auto' },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Estado', value: 'endereco.estado.sigla', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'CEP', value: 'endereco.cep', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('unidadesNegocio', ['ready', 'unidadesNegocio']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.hasModule('unidadesNegocio')) { this.$store.registerModule('unidadesNegocio', UnidadesNegocioStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('unidadesNegocio', [BOOTSTRAP, DELETE_UNIDADE]),
      editarUnidadeNegocio ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/unidadesnegocio/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
